import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'swiper/css/swiper.css'
import '@/assets/theme/index.less'
Vue.config.productionTip = false
router.beforeEach((to, from, next) => { // 路由守卫
  document.title = to.matched[0].meta.title // 改标签
  window.scrollTo(0, 0)
  next() // 必写
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
