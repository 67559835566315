/*
 * @Author: Zane
 * @Date: 2023-01-31 10:23:29
 * @Description:
 * @LastEditors: Zane
 * @LastEditTime: 2024-05-20 15:54:44
 * @FilePath: /dbox-website-pc/src/config/env.development.js
 */
// 本地环境配置
module.exports = {
  title: 'dbox-mobile',
  baseUrl: 'http://localhost:9018', // 项目地址
  baseApi: 'https://nft-test-api.dboxtech.com', // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  $cdn: ''
}
