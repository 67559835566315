import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/home/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "AppLayout",
    component: () => import("@/views/layouts/index"),
    redirect: "/home",
    meta: {
      title: "数盒科技",
      keepAlive: false,
    },
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
          title: "数盒科技",
        },
      },

      {
        path: "/contactus/ContactusView",
        name: "Contactus",
        component: () => import("../views/contactus/ContactusView.vue"),
        meta: {
          title: "关于我们",
        },
      },
      {
        path: "/news/NewsView",
        name: "News",
        component: () => import("../views/news/NewsView.vue"),
        meta: {
          title: "新媒体中心",
        },
      },
      {
        path: "/news/NewsDetails",
        name: "NewsDet",
        component: () => import("../views/news/NewsDetails.vue"),
        meta: {
          title: "新媒体中心",
        },
      },
      // {
      //   path: '/joinus/JoinusView',
      //   name: 'JoinUs',
      //   component: () => import('../views/joinus/JoinusView.vue'),
      //   meta: {
      //     title: '加入我们'
      //   }
      // },
      {
        path: "/joinus/JoinusDetails",
        name: "JoinUsDet",
        component: () => import("../views/joinus/JoinusDetails.vue"),
        meta: {
          title: "加入我们",
        },
      },
      //数盒元宇宙下线
      // {
      //   path: '/metaverse/MetaverseView',
      //   name: 'metaverse',
      //   component: () => import('../views/metaverse/MetaverseView.vue'),
      //   meta: {
      //     title: '数盒元宇宙'
      //   }
      // },
      {
        path: "/grain/grainView",
        name: "grain",
        component: () => import("../views/grain/GrainView.vue"),
        meta: {
          title: "数盒谷",
        },
      },
      //艺术管理交易平台下线
      // {
      //   path: "/aura/AuraView",
      //   name: "aura",
      //   component: () => import("../views/aura/AuraView.vue"),
      //   meta: {
      //     title: "AURA",
      //   },
      // },
      {
        path: "/chain/chainView",
        name: "chain",
        component: () => import("../views/chain/ChainView.vue"),
        meta: {
          title: "数盒链",
        },
      },
      {
        path: "*",
        name: "home",
        component: HomeView,
        meta: {
          title: "数盒科技",
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
