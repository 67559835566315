import { request } from "../utils/request";
//新闻列表
// export function getNewsList(data) {
//   return request("/cms/defNewsCenter/findDefNewsCenter", "get", data);
// }
//新闻列表
export function getNewsList(data) {
  return request("/digital/h5/dig-home/home-news", "get", data);
}
// export function getNewsDerails(url) {
//   return request(`/cms/defNewsCenter/?id=${url}`, "get");
// }
