const state = {
  isMobile: true // 移动端

}
const mutations = {

}
export default {
  state,
  mutations

}
