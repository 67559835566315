import axios from "axios";
// 根据环境不同引入不同api地址
import { baseApi } from "@/config";
// axios.defaults.baseURL = 'http://192.168.1.188:9999/cms'
// axios.defaults.baseURL = 'http://192.168.1.251:9999/cms'
// axios.defaults.baseURL = 'https://test-admin.dboxbj.com/cms' // 测试环境
const service = axios.create({
  baseURL: baseApi, // url = base api url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout
});
// 请求拦截
service.interceptors.request.use(
  (config) => {
    // const token = `eyJ0eXAiOiJKc29uV2ViVG9rZW4iLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyTmFtZSI6Inh1ZXdlaSIsInVzZXJJZCI6IjE3MDIzMjA3MjI0MjExNDE1MDUiLCJpYXQiOjE3MTAyMzIxNDMsIm5iZiI6MTcxMDIzMjE0MywiZXhwIjoxNzEzODMyMTQzfQ.5RqWJPIndpfakFaNfy6BWasKWTkFwcLK3uWEx5uEUXQ`;
    // console.log(token);
    // if (token) {
    //   config.headers.Digtoken = token;
    // }
    return config;
  },
  error => Promise.reject(error)

);
let p = null;
function request(url, type = "get", data = {}) {
  return new Promise(function (resolve, reject) {
    if (type === "get" || type === "delete") {
      p = service.get(url, {
        params: data,
      });
    } else if (type === "post" || type === "put") {
      p = service.post(url, data);
    }
    p.then((res) => {
      resolve(res.data);
    }).catch((err) => {
      reject(err);
    });
  });
}

export { request };
