<template>
  <div class="home">
    <div class="swiper">
      <swiper ref="mySwiper" :options="swiperOptions" class="swipers">
        <swiper-slide
          v-for="(item, index) in titleList"
          :key="index"
          class="swiper-slides"
        >
          <!-- <img src="../../assets/home/banner1.png" alt=""> -->
          <div class="swiper-img">
            <div class="img">
              <img :src="item.src" alt="" />
            </div>
          </div>
          <!-- <div>{{item}}</div> -->
        </swiper-slide>
        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
      <div class="swiper-tit">
        <p class="swiper-tit-tit">{{ swiperTiele }}</p>
        <p class="swiper-tit-note">{{ swiperNote }}</p>
        <div class="swiper-tit-icon">
          <div v-for="(item, index) in swiperTiticon" :key="index">
            <img src="@/assets/home/hoem-tit-icon.svg" alt="" />

            <p>{{ item.text }}</p>
          </div>
        </div>
        <div class="swiper-tit-button" @click="swiperJump">more</div>
      </div>
    </div>
    <!-- 产品呈现 -->
    <div class="product">
      <module-header :moduleheader="moduleheader3"></module-header>
      <div class="product-contaiber">
        <div class="product-swiper">
          <swiper :options="productSwiper" class="product-list">
            <swiper-slide
              v-for="(item, index) in productList"
              :key="index"
              class="product-slide"
            >
              <div class="product-slide-list">
                <div class="product-slide-list-container">
                  <div class="product-swiper-img">
                    <img :src="item.url" alt="" />
                    <div class="product-cover-list1">
                      <div class="product-cover">
                        <p class="product-cover-tit">
                          {{ item.text }}
                        </p>
                        <p class="product-cover-note">点击下方查看更多内容</p>
                        <p class="product-cover-border"></p>
                        <!-- product-cover-button class关联点击事件 -->
                        <div class="product-cover-button" :data-index="index">
                          MORE
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="product-tit">{{ item.text }}</p>
                  <p class="product-note">{{ item.note }}</p>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </div>
    <!-- 概念片 -->
    <div class="concept">
      <module-header
        v-show="videoflag2"
        :moduleheader="moduleheader2"
      ></module-header>
      <div class="concept-logo" v-show="videoflag">
        <img src="../../assets/home/concept-note.png" alt="" />
      </div>
      <div class="concept-play" @click="videoisShow" v-show="videoflag">
        <img src="../../assets/home/play.svg" alt="" class="concept-img" />
        <img src="../../assets/home/pause.png" alt="" class="concept-img2" />
      </div>
      <div class="concept-cover" @click="videoisShow">
        <div
          class="concept-article"
          @click.stop="
            () => {
              return;
            }
          "
        >
          <div class="concept-video">
            <video
              src="https://static.dboxtech.com/dboxtech/dboxtech.mp4"
              controls
            >
              <!-- <video src="https://ssv-1308614571.file.myqcloud.com/2022-08-30/1661842417826.mp4" controls> -->
              <!-- <source src="https://tbm-auth.alicdn.com/55c84e05b0223b7b/1648459825426/3e2426b6adb2a1f6_MOV_352597440537_mp4_264_hd_taobao.mp4?auth_key=1661504555-0-0-1646a67f7cf19d6eeccffdcfe5a77256&t=21362b1316615018553517283e28e3&b=guangguang&p=cloudvideo_http_pc_video_seller_publish" type="video/mp4"> -->
            </video>
          </div>
          <!-- <p class="concept-tit">数盒科技视频宣传</p>
          <p class="concept-note">
            数盒科技视频宣传国内首批成功备案的区款链服务提供商
          </p> -->
        </div>
      </div>
    </div>
    <!-- 数盒优势 -->
    <div class="advantage">
      <module-header :moduleheader="moduleheader1"></module-header>
      <div class="advantage-container">
        <div class="advantage-list">
          <div
            class="advantage-container-list"
            v-for="item in advantageList"
            :key="item.id"
          >
            <img :src="item.url" class="advantage-container-list-img" alt="" />
            <img
              :src="item.activateUrl"
              class="advantage-container-list-imghover"
              alt=""
            />
            <p class="advantage-text">{{ item.text }}</p>
            <p class="advantage-note">{{ item.note }}</p>
            <p class="advantage-id">{{ item.id }}/</p>
            <div class="advantage-container-list-border"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新闻中心 -->
    <div class="news">
      <module-header
        v-show="videoflag2"
        :moduleheader="moduleheader4"
      ></module-header>
      <div class="b">
        <div class="news-container">
          <swiper :options="newsSwiper" class="product-list">
            <swiper-slide v-for="(item, index) in newsList" :key="index">
              <div class="news-img">
                <img :src="item.jumpLink" alt="" />
              </div>
              <div class="news-text">
                <p class="news-tit">{{ item.title }}</p>
                <p class="news-time">
                  {{ item.createTime }}
                </p>
                <p class="news-note">{{ item.subTitle }}</p>
                <div class="swiper-tit-button" @click="pushdeTails(item.id)">
                  MORE
                </div>
              </div>

              <!-- <div>{{item}}</div> -->
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </div>
    <div class="sponsor">
      <div class="sponsor-container">
        <swiper :options="sponsorSwiper" class="product-list">
          <swiper-slide
            v-for="(item, index) in sponsorList"
            :key="index"
            class="product-slide"
          >
            <div class="product-img">
              <img :src="item.url" alt="" />
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ModuleHeader from "../../components/moduleheader/ModuleHeader.vue";
import { getNewsList } from "../../api/news";
// const that = this
export default {
  name: "HomeView",
  data() {
    return {
      swiperTiele: "数盒科技",
      swiperNote: "基于数字化技术打造数字+文化的平台型科技公司",
      swiperTiticon: [
        { text: "数字版权确权" },
        { text: "数字版权品交易" },
        { text: "数字场景综合应用" },
        { text: "区块链技术开发" },
      ],
      swiperOptions: {
        // 自动切换
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        // 环路
        loop: false,
        on: {
          slideChangeTransitionStart: () => {
            // console.log(this.activeIndex)
            // console.log(this)
            // this.swiperSlide = this.activeIndex
            this.slideChange();
          },
        },
        // 前进后退按钮
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // 分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      // 产品呈现swiper配置
      productSwiper: {
        // 自动切换
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        // 环路
        loop: true,
        loopedSlides: 7,
        slidesPerView: "auto",
        cache: true,
        // 前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          click: (event) => {
            let dom = event.target;
            if (dom.className == "product-cover-button") {
              let index = event.target.dataset.index;
              if (this.productList[index].path) {
                this.$router.push({
                  path: this.productList[index].path,
                });
              } else {
                // console.log("没有跳转链接");
              }
            }
          },
        },
      },
      // 新闻中心swiper配置
      newsSwiper: {
        // 自动切换
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 环路
        // loop: true,
        // 前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      // 底部swiper配置
      sponsorSwiper: {
        slidesPerView: 6.2,
        // 自动切换
        // autoplay: true,
        // 环路
        loop: true,
        // 前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },

      moduleheader1: {
        text: "数盒优势",
        note: "ADVANTAGE",
        flag: false,
      },
      moduleheader2: { text: "概念片", note: "CONCEPT", flag: false },
      moduleheader3: {
        text: "产品呈现",
        note: "PRODUCT",
        flag: false,
        age: 0,
      },
      moduleheader4: {
        text: "新媒体中心",
        note: "NEWS",
        flag: true,
        age: 1,
      },
      advantageList: [
        {
          id: "01",
          text: "网信办认证",
          note: "国内首批成功备案的区块链服务提供商",
          url: require("../../assets/home/advantage/wxb.svg"),
          activateUrl: require("../../assets/home/advantage/wxbactivate.svg"),
        },
        {
          id: "02",
          text: "国家级版权服务",
          note: "为数字艺术作品提供国家级版权登记服务",
          url: require("../../assets/home/advantage/bc.svg"),
          activateUrl: require("../../assets/home/advantage/bcactivate.svg"),
        },
        {
          id: "03",
          text: "青文交授权",
          note: "获得青岛文化产权交易中心授权运营文博科技中心",
          url: require("../../assets/home/advantage/yy.svg"),
          activateUrl: require("../../assets/home/advantage/yyactivate.svg"),
        },
        {
          id: "04",
          text: "发起标准制定",
          note: "《基于区块链的版权保护服务技术要求》标准制定发起方、副组长单位",
          url: require("../../assets/home/advantage/gfzd.svg"),
          activateUrl: require("../../assets/home/advantage/gfzdactivate.svg"),
        },
        {
          id: "05",
          text: "腾讯生态合作",
          note: "腾讯SSV数字文化实验室生态共创合作伙伴",
          url: require("../../assets/home/advantage/txst.svg"),
          activateUrl: require("../../assets/home/advantage/txstactivate.svg"),
        },

        {
          id: "06",
          text: "合规治理委员会",
          note: "邀请知识产权侧、市场监督侧等组织参与创建合规治理委员会",
          url: require("../../assets/home/advantage/xmjy.svg"),
          activateUrl: require("../../assets/home/advantage/xmjyactivate.svg"),
        },
      ],
      productList: [
        {
          text: "数字文化基因库",
          note: "技术开发平台",
          url: require("../../assets/home/product/img1.jpg"),
          // path: "/metaverse/MetaverseView",
        },
        {
          text: "数字场景综合管理服务系统",
          note: "管理平台",
          url: require("../../assets/home/product/img2.jpg"),
          // path: "/grain/grainView",
        },
        {
          text: "数字版权品交易平台",
          note: "交易平台",
          url: require("../../assets/home/product/img3.jpg"),
          // path: "/aura/AuraView",
        },
        {
          text: "数字文化基因库",
          note: "技术开发平台",
          url: require("../../assets/home/product/img1.jpg"),
          // path: "/metaverse/MetaverseView",
        },
        {
          text: "数字场景综合管理服务系统",
          note: "管理平台",
          url: require("../../assets/home/product/img2.jpg"),
          // path: "/grain/grainView",
        },
        {
          text: "数字版权品交易平台",
          note: "交易平台",
          url: require("../../assets/home/product/img3.jpg"),
          // path: "/aura/AuraView",
        },
      ],
      newsList: [],
      titleList: [
        { src: require("@/assets/home/banner1.jpg") },
        // { src: require("@/assets/home/banner2.jpg") },
      ],
      sponsorList: [
        { url: require("../../assets/home/sponsor/SSV.png") },
        { url: require("../../assets/home/sponsor/bjwb.png") },
        { url: require("../../assets/home/sponsor/lyxh.png") },
        { url: require("../../assets/home/sponsor/zglw.png") },
        // { url: require('../../assets/home/sponsor/moremeta.png') },
        { url: require("../../assets/home/sponsor/gwj.png") },
        { url: require("../../assets/home/sponsor/hc.png") },
        { url: require("../../assets/home/sponsor/xgwh.png") },
        { url: require("../../assets/home/sponsor/3chaos.png") },
        { url: require("../../assets/home/sponsor/apcm.png") },
        { url: require("../../assets/home/sponsor/yslh.png") },
        { url: require("../../assets/home/sponsor/cywh.png") },
        { url: require("../../assets/home/sponsor/zhkj.png") },
        { url: require("../../assets/home/sponsor/jct.png") },
        { url: require("../../assets/home/sponsor/jhzj.png") },
        { url: require("../../assets/home/sponsor/xy.png") },
        { url: require("../../assets/home/sponsor/CAOVIT.png") },
      ],
      videoflag: true,
      videoflag2: true,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    ModuleHeader,
  },
  methods: {
    // 顶部banner文案变化
    slideChange() {
      this.$nextTick(() => {
        // if (
        //   this.$refs.mySwiper.$swiper.activeIndex === 2 ||
        //   this.$refs.mySwiper.$swiper.activeIndex === 0
        // ) {
        //   this.swiperTiticon = [
        //     { text: "空间建设" },
        //     { text: "内容互动" },
        //     { text: "品牌营销" },
        //     { text: "场景展示" },
        //     { text: "电子商务" },
        //     { text: "用户共创" },
        //   ];
        //   this.swiperTiele = "数盒元宇宙";
        //   this.swiperNote = "基于区块链和虚拟现实技术打造的综合元宇宙";
        // } else {
        //   this.swiperTiticon = [
        //     { text: "数字版权确权" },
        //     { text: "数字资产交易" },
        //     { text: "MR场景综合应用" },
        //     { text: "区块链技术开发" },
        //   ];
        //   this.swiperTiele = "数盒科技";
        //   this.swiperNote = "基于数字化技术打造数字+文化的平台型科技公司";
        // }
      });
    },
    concept() {
      const e = document.querySelector(".concept-img");
      e.style.visibility = "hidden";
    },
    swiperJump() {
      this.$nextTick(() => {
        // if (
        //   this.$refs.mySwiper.$swiper.activeIndex === 2 ||
        //   this.$refs.mySwiper.$swiper.activeIndex === 0
        // ) {
        //   this.$router.push("/metaverse/MetaverseView");
        // } else {
        //   this.$router.push("/contactus/ContactusView");
        // }
        this.$router.push("/contactus/ContactusView");
      });
    },
    videoisShow() {
      this.videoflag = !this.videoflag;
      const e = document.querySelector(".concept-cover");
      const video = document.querySelector("video");
      if (this.videoflag === false) {
        e.style.opacity = 1;
        this.videoflag2 = this.videoflag;
        e.style.zIndex = 1;
        video.play();
      } else {
        setTimeout(() => {
          this.videoflag2 = this.videoflag;
          e.style.zIndex = -2;
        }, "300");
        e.style.opacity = 0;
        video.pause();
      }
    },
    getList() {
      getNewsList().then((res) => {
        this.newsList = res.data;
      });
    },
    pushdeTails(e) {
      this.$router.push(`/news/NewsDetails/?id=${e}`);
    },
  },
  mounted() {
    this.getList();
    window.scrollTo(0, 0);
    document.title = "数盒科技";
  },
};
</script>
<style lang="less" scoped>
.home {
  background: #fff;
}
.swiper {
  // --swiper-theme-color: #ff6600;/* 设置Swiper风格 */
  --swiper-navigation-color: rgba(51, 51, 51, 0.5); /* 单独设置按钮颜色 */
  --swiper-navigation-size: 24px; /* 设置按钮大小 */
}
.swiper-button-prev {
  left: 18.75%;
  top: 93%;
  // width: 80px;
  // height: 80px;
  // aspect-ratio: 1/1;
  padding: 1.5% 2% 1.5% 2%;
  background: #fff;
  transition: 0.5s;
}
.swiper-button-prev::after {
  width: 14px;
  height: 24px;
  padding: 1.5%;
  font-family: none;
  content: "";
  // --swiper-navigation-color: #333; /* 单独设置按钮颜色 */
  background: url("@/assets/home/prvehover.png") no-repeat;
  transition: 0.5s;
}
.swiper-button-prev:hover {
  background: #f6f6f6 !important;
}
.swiper-button-prev:hover::after {
  // background: #f6f6f6 !important;
  width: 14px;
  height: 24px;
  padding: 1.5%;
  font-family: none;
  content: "";
  // --swiper-navigation-color: #333; /* 单独设置按钮颜色 */
  background: url("@/assets/home/prve.png") no-repeat;
}
.swiper-button-next {
  left: 18.75%;
  margin-left: 4.5%;
  top: 93%;
  // width: 80px;
  // aspect-ratio: 1/1;
  // height: 80px;
  padding: 1.5% 2% 1.5% 2%;
  background: #fff;
  transition: 0.5s;
}
.swiper-button-next::after {
  width: 14px;
  height: 24px;
  padding: 1.5%;
  font-family: none;
  content: "";
  // --swiper-navigation-color: #333; /* 单独设置按钮颜色 */
  background: url("@/assets/home/nexthover.png") no-repeat;
  transition: 0.5s;
}
.swiper-button-next:hover {
  background: #f6f6f6 !important;
  --swiper-navigation-color: #333; /* 单独设置按钮颜色 */
}
.swiper-button-next:hover::after {
  // background: #f6f6f6 !important;
  width: 14px;
  height: 24px;
  padding: 1.5%;
  font-family: none;
  content: "";
  // --swiper-navigation-color: #333; /* 单独设置按钮颜色 */
  background: url("@/assets/home/next.png") no-repeat;
}
.swiper {
  position: relative;
  // height: 950px;
  width: 100%;
  // padding: 30% 0 30% 0;
  // aspect-ratio: 192/96;
  // .swipers{
  //   aspect-ratio: 192/96;
  // }
  .swiper-tit {
    position: absolute;
    text-align: left;
    top: 28%;
    left: 18.75%;
    z-index: 99;
    color: #333;
    .swiper-tit-tit {
      font-size: 60px;
      font-weight: 500;
    }
    .swiper-tit-note {
      margin-top: 20px;
      font-size: 28px;
      letter-spacing: 6px;
    }
    .swiper-tit-icon {
      margin-top: 25px;
      width: 440px;
      display: flex;
      transition: 0.25s;
      // justify-content: space-between;
      flex-wrap: wrap;
      div {
        transition: 0.25s;
        display: flex;
        width: 164px;
        height: 32px;
        border-radius: 8px;
        font-size: 16px;
        text-align: center;
        line-height: 32px;
        // border: 1px solid #333;
        margin: 0 30px 10px 0;
        align-items: center;
        img {
          width: 16px;
          height: 12px;
          margin-right: 5px;
        }
      }
    }
  }
}
.swiper-tit-button {
  width: 120px;
  height: 32px;
  border: 1px solid;
  border-image: linear-gradient(
      to right,
      #619af5,
      #16cefc,
      #1ed7ea,
      #08f8c5,
      #24fea7
    )
    1;
  text-align: center;
  line-height: 32px;
  margin-top: 30px;
  transition: 0.5s;
  cursor: pointer;
}
.swiper-tit-button:hover {
  color: #fff;
  background: -webkit-linear-gradient(
    left,
    #619af5,
    #16cefc,
    #1ed7ea,
    #08f8c5,
    #24fea7
  );
}
.swiper-slides {
  width: 100%;
  height: 100%;
  // padding: 30% 0 30% 0;
  .swiper-img {
    width: 100%;
    // height: 50px;
    // aspect-ratio: 192/96;
    // height:100%;
    box-sizing: border-box;
    div {
      width: 100%;
      height: 100%;
      padding: 24% 0 24% 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      object-fit: cover;
      box-sizing: border-box;
      position: relative;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
.advantage {
  width: 100%;
  height: 770px;
  .advantage-container {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .advantage-list {
      width: 1200px;

      .advantage-container-list {
        width: 400px;
        height: 200px;
        box-sizing: border-box;
        padding: 30px 0 0 40px;
        float: left;
        border: 1px solid rgba(151, 151, 151, 0.1);
        text-align: left;
        position: relative;
        margin: -1px -1px 0 0;
        img {
          position: absolute;
          top: 40px;
          left: 40px;
          transition: 0.5s;
        }
        .advantage-container-list-imghover {
          opacity: 0;
        }
        .advantage-text {
          font-size: 18px;
          margin-top: 75px;
        }
        .advantage-note {
          width: 348px;
          margin-top: 6px;
        }
        .advantage-id {
          font-size: 20px;
          position: absolute;
          right: 40px;
          top: 40px;
          color: #1b1b1b;
        }
        .advantage-container-list-border {
          box-sizing: border-box;
          // padding: 30px 30px 12px 30px;
          width: 380px;
          height: 180px;
          position: absolute;
          transition: 0.5s;
          left: 10px;
          top: 10px;
        }
      }
      .advantage-container-list:hover .advantage-container-list-img {
        opacity: 0;
      }
      .advantage-container-list:hover .advantage-container-list-imghover {
        opacity: 1;
      }
      .advantage-container-list:hover .advantage-container-list-border {
        border: 1px solid;
        border-image: linear-gradient(
            to right,
            #619af5,
            #16cefc,
            #1ed7ea,
            #08f8c5,
            #24fea7
          )
          1;
        transform: scale(0.95, 0.95);
      }
    }
  }
}
.concept {
  height: 950px;
  background-image: url("../../assets/home/concept.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  .concept-cover {
    width: 100%;
    height: 100%;
    background: rgba(51, 51, 51, 0.9);
    position: relative;
    transition: 0.5s;
    opacity: 0;
    z-index: -1;
    .concept-article {
      width: 980px;
      // height: 560px;
      background: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -240px 0 0 -490px;
      box-sizing: border-box;
      padding: 10px;
      text-align: left;
      .concept-video {
        width: 960px;
        height: 440px;
        background: #000;
        video {
          width: 100%;
          height: 100%;
        }
      }
      p {
        margin-left: 30px;
      }
      .concept-tit {
        margin-top: 30px;
        font-size: 20px;
        font-weight: 500;
      }
      .concept-note {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
  .concept-logo {
    position: absolute;
    top: 274px;
    left: 68%;
  }
  .concept-play {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -45px 0 0 -45px;
    width: 90px;
    height: 90px;
    z-index: 9;
    cursor: pointer;
    .concept-img {
      position: absolute;
      left: 0;
      top: 0;
      transition: 0.5s;
    }
    .concept-img2 {
      position: absolute;
      left: 0;
      top: 0;
      transition: 0.5s;
      opacity: 0;
    }
  }
  .concept-play:hover .concept-img {
    opacity: 0;
  }
  .concept-play:hover .concept-img2 {
    opacity: 1;
  }
}
.product {
  width: 100%;
  // aspect-ratio: 192/95;
  .product-contaiber {
    width: 100%;
    // aspect-ratio: 192/78;
    // padding: 0 0 15% 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background-image: url();
    .product-swiper {
      width: 95%;
      // aspect-ratio: 192/80;
      margin: 100px 0 0 5%;
      pointer-events: auto;
      .product-list {
        --swiper-navigation-color: rgba(
          51,
          51,
          51,
          0.5
        ) !important; /* 单独设置按钮颜色 */
        --swiper-navigation-size: 24px; /* 设置按钮大小 */
        // height: 600px;
        // aspect-ratio: 192/70;
        .swiper-button-prev {
          left: 50%;
          top: 80%;
          width: 80px;
          height: 80px;
          background: #fff;
          margin-left: -120px;
          transition: 0.5s;
          padding: 0;
          // pointer-events:auto;
          // pointer-events:none;
        }
        .swiper-button-next {
          left: 50%;
          top: 80%;
          width: 80px;
          height: 80px;
          background: #fff;
          margin-left: -40px;
          transition: 0.5s;
          padding: 0;
          // pointer-events:auto;
          // pointer-events:none;
        }
      }
      .product-slide {
        width: 22%;
        margin-right: 10px;
        // height: 400px;
        // aspect-ratio: 21/35;
        // padding: 0 0 13% 0;
        margin-bottom: 13%;
        .product-slide-list {
          // aspect-ratio: 21/36;
          text-align: left;
          // .product-slide-list-container {
          //   // aspect-ratio: 21/19;
          // }
          .product-swiper-img {
            width: 100%;
            aspect-ratio: 1/1;
            background: #fff;
            overflow: hidden;
            // padding: 5%;
            // display: flex;
            position: relative;
            .product-cover-list1 {
              width: 100%;
              height: 100%;
              // padding: 5%;
              box-sizing: border-box;
            }
            .product-cover {
              background: rgba(255, 255, 255, 0.9);
              width: 90%;
              height: 92%;
              // padding: 30% 0 20% 0;
              aspect-ratio: 1/1;
              // -ms-aspect-ratio: 1/1;
              // -webkit-aspect-ratio: 1/1;
              position: absolute;
              left: 5%;
              top: 5%;
              box-sizing: border-box;
              border: 2px solid;
              border-image: linear-gradient(
                  to right,
                  #619af5,
                  #16cefc,
                  #1ed7ea,
                  #08f8c5,
                  #24fea7
                )
                1;
              text-align: center;
              opacity: 0;
              transition: 0.5s;
              .product-cover-tit {
                font-size: 18px;
                color: #1b1b1b;
                margin-top: 30%;
              }
              .product-cover-note {
                color: #1b1b1b;
                opacity: 0.7;
                margin-top: 7px;
              }
              .product-cover-border {
                width: 1px;
                height: 30px;
                background: #1b1b1b;
                margin: 10px auto 0;
              }
              .product-cover-button {
                width: 70px;
                height: 26px;
                border: 1px solid;
                transition: 0.5s;
                margin: 10px auto 0;
                // margin: 10px auto 24%;
                font-size: 12px;
                line-height: 26px;
                border-image: linear-gradient(
                    to right,
                    #619af5,
                    #16cefc,
                    #1ed7ea,
                    #08f8c5,
                    #24fea7
                  )
                  1;
                cursor: pointer;
              }
              .product-cover-button:hover {
                // width: 72px;
                // height: 28px;
                color: #fff;
                background: -webkit-linear-gradient(
                  left,
                  #619af5,
                  #16cefc,
                  #1ed7ea,
                  #08f8c5,
                  #24fea7
                );
                // border: none;
              }
            }
            img {
              width: 100%;
              height: 100%;
              transition: 0.5s;
              float: left;
            }
          }
          .product-tit {
            font-size: 18px;
            margin: 20px 0 0 24px;
            transition: 0.5s;
          }
          .product-note {
            font-size: 14px;
            margin: 10px 0 0 24px;
            transition: 0.5s;
          }
          .product-slide-list-container:hover .product-tit {
            opacity: 0;
          }
          .product-slide-list-container:hover .product-note {
            opacity: 0;
          }
          .product-slide-list-container:hover img {
            transform: scale(1.1, 1.1);
          }
          .product-slide-list-container:hover .product-cover {
            top: 3%;
            opacity: 1;
          }
        }
      }
    }
  }
}
.news {
  width: 100%;
  height: 950px;
  background-image: url("../../assets/home/xwzx.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  position: relative;
  background-size: 100% 100%;
  .b {
    width: 100%;
    height: 782px;
    display: flex;
    justify-content: space-around;
    // align-items: center;
  }
  .news-container {
    width: 1200px;
    height: 280px;
    margin-top: 9%;
    // position: absolute;
    // top:50%;
    // left: 50%;
    // margin: -150px 0 0 -550px;
    // background: #999;
    .product-list {
      --swiper-navigation-color: rgba(
        51,
        51,
        51,
        0.5
      ) !important; /* 单独设置按钮颜色 */
      --swiper-navigation-size: 24px; /* 设置按钮大小 */
      .swiper-button-prev {
        left: 100%;
        top: 50%;
        width: 80px;
        height: 80px;
        background: #fff;
        margin: -40px 0 0 -180px;
        transition: 0.5s;
        padding: 0;
      }
      .swiper-button-next {
        left: 100%;
        top: 50%;
        width: 80px;
        height: 80px;
        background: #fff;
        margin: -40px 0 0 -100px;
        transition: 0.5s;
        padding: 0;
      }
    }
    .news-img {
      width: 420px;
      height: 300px;
      float: left;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        transition: 0.5s;
      }
    }
    .news-img:hover img {
      transform: scale(1.1, 1.1);
    }
    .news-text {
      float: left;
      margin: 50px 0 0 50px;
      color: #1b1b1b;
      text-align: left;
      width: 730px;
      .news-tit {
        font-size: 20px;
      }
      .news-time {
        font-size: 14px;
        margin-top: 10px;
      }
      .news-note {
        font-size: 14px;
        margin-top: 20px;
        opacity: 0.7;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 500px;
      }
    }
  }
}
.sponsor {
  width: 100%;
  // height: 420px;
  padding: 2% 0 2% 0;
  // aspect-ratio: 192/42 !important;
  .sponsor-container {
    width: 95%;
    margin: 0 0 0 5%;
    // height: 270px;
    float: left;
    //  aspect-ratio: 192/78;
    padding: 3% 0 3% 0;
    .product-list {
      --swiper-navigation-color: rgba(
        51,
        51,
        51,
        0.5
      ) !important; /* 单独设置按钮颜色 */
      --swiper-navigation-size: 24px; /* 设置按钮大小 */
      // height: 230px;
      // aspect-ratio: 192/23;
      // padding: 2% 0 2% 0;
      .swiper-button-prev {
        left: 50%;
        top: 74%;
        // width: 80px;
        // height: 80px;
        background: #fff;
        margin-left: -6.8%;
        transition: 0.5s;
        // padding: 0;
      }
      .swiper-button-next {
        left: 50%;
        top: 74%;
        // width: 80px;
        // height: 80px;
        background: #fff;
        margin-left: -2.2%;
        transition: 0.5s;
        // padding: 0;
      }
    }
    .product-list {
      width: 100%;
      //  aspect-ratio: 192/78;
      .product-slide {
        width: 7%;
        // aspect-ratio: 21/19;
        padding: 3% 0 5% 0;
        .product-img {
          // width: 100%;
          // aspect-ratio: 21/19;
          margin-right: 20px;
          transition: 0.5s;
          display: flex;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .product-img:hover {
          // box-shadow: 0px 0px 5px #c2c2c2;
          box-shadow: 1px 1px 6px #666;
        }
      }
    }
  }
}
</style>
