<template>
  <div class="moduleheader">
    <p class="tit">{{moduleheader.text}} <span>{{moduleheader.note}}</span></p>
    <p class="more"
       v-if="moduleheader.flag"
       @click="skip(moduleheader.age)">更多</p>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['moduleheader'],
  data () {
    return {
      url: [
        { url: 'product/ProductView' },
        { url: 'news/NewsView' }
      ]
    }
  },
  watch: {},
  computed: {},
  methods: {
    skip (e) {
      this.$router.push(this.url[e].url)
      // console.log(this.url[e])
    }
  },
  created () { },
  mounted () { }
}
</script>
<style lang="less" scoped>
.moduleheader {
  width: 100%;
  height: 168px;
  border-bottom: 1px solid #f8f8f8;
  padding: 0 5% 0 5%;
  box-sizing: border-box;
  position: relative;
  // display: flex;
  .tit {
    font-size: 26px;
    line-height: 80px;
    border-bottom: 2px solid;
    border-image: linear-gradient(
        to right,
        #619af5,
        #16cefc,
        #1ed7ea,
        #08f8c5,
        #24fea7
      )
      1;
    text-align: left;
    position: absolute;
    left: 5%;
    bottom: 0;
    span {
      font-size: 14px;
      position: absolute;
      left: 110%;
      top: 6px;
    }
  }
  .more {
    position: absolute;
    right: 5%;
    bottom: 18px;
    color: #1b1b1b;
    font-size: 16px;
    line-height: 30px;
    border-bottom: 2px solid;
    border-image: linear-gradient(
        to right,
        #619af5,
        #16cefc,
        #1ed7ea,
        #08f8c5,
        #24fea7
      )
      1;
    cursor: pointer;
  }
}
</style>
