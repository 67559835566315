<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    const environment = process.env.VUE_APP_ENV;
    if (environment != "development") {
      this.isMobile();
      window.onresize = () => {
        this.isMobile();
      };
    }
  },
  methods: {
    isMobile() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      var bIsMidp = sUserAgent.match(/midp/i) == "midp";
      var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      var bIsAndroid = sUserAgent.match(/android/i) == "android";
      var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      if (
        bIsIpad ||
        bIsIphoneOs ||
        bIsMidp ||
        bIsUc7 ||
        bIsUc ||
        bIsAndroid ||
        bIsCE ||
        bIsWM ||
        document.body.clientWidth < 700 ||
        window.screen.width < 700
      ) {
        // window.location.href = "https://h5.dboxbj.com";
        window.location.href = "https://h5.dboxtech.com";
        return true;
      } else {
        // window.location.href = 'https://www.dboxtech.com/#/'
        return false;

        // this.$router.push(`/haishi/haishiDetails?id=${this.$route.query.id}`)
      }
    },
  },
};
</script>
<style lang="less"></style>
