/*
 * @Author: Zane
 * @Date: 2023-01-31 10:23:29
 * @Description:
 * @LastEditors: Zane
 * @LastEditTime: 2023-03-27 15:29:44
 * @FilePath: /dbox-mobile/src/config/env.production.js
 */
// 正式
module.exports = {
  title: 'dbox-mobile',
  baseUrl: 'https://www.xxx.com/', // 正式项目地址
  baseApi: 'https://service.huantang.dboxtech.com', // 正式api请求地址
  $cdn: ''
}
