/*
 * @Author: Zane
 * @Date: 2023-01-31 10:23:29
 * @Description:
 * @LastEditors: Zane
 * @LastEditTime: 2024-05-20 15:54:47
 * @FilePath: /dbox-website-pc/src/config/env.staging.js
 */
module.exports = {
  title: 'dbox-mobile',
  baseUrl: 'https://test.xxx.com', // 测试项目地址
  baseApi: 'https://nft-test-api.dboxtech.com', // 测试api请求地址
  $cdn: ''
}
